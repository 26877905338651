:root {
  --box-spacing: 20px;
  --box-main-padding: 30px;
  --box-max-width: 1024px;

  --wai-height: 45px;
  --wai-max-width: 270px;
  --wai-main-border-radius: 8px;

  --hub-max-width: 800px;
  --scrollbar-width: 6px;

  --main-transparency: 10%;
  --main-border-radius: 3px;
  --main-bigger-border-radius: 6px;

  --blurred-box-shadow: 0 0 16px 1px rgba(0, 0, 0, 0.3);

  --main-box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.1);
  --main-right-box-shadow: 1px 0 2px 0 rgba(0, 0, 0, 0.1);
  --main-top-box-shadow: 0 -1px 2px 0 rgba(0, 0, 0, 0.1);
  --main-bottom-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);

  --strong-box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.3);
  --strong-right-box-shadow: 2px 0 4px 1px rgba(0, 0, 0, 0.3);
  --strong-top-box-shadow: 0 -2px 4px 1px rgba(0, 0, 0, 0.3);
  --strong-bottom-box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.3);

  --color-favorite: #5e12d3;
  --color-main-background: #f4f4f4;

  --color-white: #ffffff;
  --color-strong-blue: #4f0eaf;

  --color-black: #000000;
  --color-weak-black: #333333;

  --color-grey: #a4a4a4;
  --color-teen-grey: #d9d9d9;
  --color-white-grey: #eeeeee;
  --color-strong-grey: #8f8f8f;

  --color-light-red: #f84444;
  --color-light-green: #1ebb5d;

  --color-error: #fa7878;
}

@media (min-width: 640px) {
  :root {
    --box-spacing: 30px;
  }
}

@media (min-width: 768px) {
  :root {
    --box-spacing: 35px;
  }
}

@media (min-width: 1024px) {
  :root {
    --box-spacing: 40px;
  }
}

@media (min-width: 1280px) {
  :root {
    --box-spacing: 50px;
  }
}

@media (min-width: 1536px) {
  :root {
    --box-spacing: 60px;
  }
}
